import React, { useEffect, useState, useRef } from "react";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button
} from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import HexToRgba from '../../components/visuals/design/hexToRgba';
import "../../components/visuals/design/skSpecial.css";

const Negroni24 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [data, setData] = useState([]);
    const [groupTotals, setGroupTotals] = useState({});
    const scrollContainerRef = useRef(null);
    const topScrollContainerRef = useRef(null);
    const [tableWidth, setTableWidth] = useState(0);

    const [chS02E01Views, setChS02E01Views] = useState("Not available"); // <-- useState to store the views
    const [chS02E01FamilyTreeViews, setChS02E01FamilyTreeViews] = useState("Not available");

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/negroni`);
            const data = await response.json();

            if (response.ok) {
                setData(data);
            }
        };

        const fetchGroupTotals = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/negroni/totals`);
            const totals = await response.json();

            if (response.ok) {
                setGroupTotals(totals);
            }
        };

        const fetchYtData = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/negroni/yt`);
            const totals = await response.json();

            if (response.ok && totals.length >= 2) {
                setChS02E01Views(totals[0]?.YTviews !== undefined ? totals[0].YTviews : "Not available");
                setChS02E01FamilyTreeViews(totals[1]?.YTviews !== undefined ? totals[1].YTviews : "Not available");
            }
        };

        fetchData();
        fetchGroupTotals();
        fetchYtData();

        if (scrollContainerRef.current) {
            setTableWidth(scrollContainerRef.current.scrollWidth);
        }
    }, []);

    const handleScroll = (scrollContainer, syncedScrollContainer) => {
        if (syncedScrollContainer.current && scrollContainer.current) {
            syncedScrollContainer.current.scrollLeft = scrollContainer.current.scrollLeft;
        }
    };

    const group1Imp = groupTotals[1]?.totalImps || 0;
    // const group1Views = groupTotals[1]?.totalSeptViews || 0;
    const group2Imp = groupTotals[2]?.totalImps || 0;
    // const group2Views = groupTotals[2]?.totalSeptViews || 0;

    const groupImps = {
        1: group1Imp,
        2: group2Imp,

    };

    // Function to convert data into CSV format and trigger download
    const downloadCSV = () => {
        const csvHeader = [
            "Prisma Code,Tactic,Flight Start,Flight End,Buy Model,Planned Impressions,Planned Dollars,Total Imps,Total Clicks,Total Views,Sept Imps,Sept Clicks,Sept Views,Oct Imps,Oct Clicks,Oct Views"
        ];

        const csvRows = data.map(row => [
            `"${row.prismaCode || ''}"`,
            `"${row.tactic || ''}"`,
            `"${row.flightStart || ''}"`,
            `"${row.flightEnd || ''}"`,
            `"${row.buyModel || ''}"`,
            `"${row.plannedImp || ''}"`,
            `"${row.plannedDollars || ''}"`,
            `"${row.totalImp || ''}"`,
            `"${row.totalClicks || ''}"`,
            `"${row.totalViews || ''}"`,
            `"${row.septImps || ''}"`,
            `"${row.septClicks || ''}"`,
            `"${row.septViews || ''}"`,
            `"${row.octImps || ''}"`,
            `"${row.octClicks || ''}"`,
            `"${row.octViews || ''}"`
        ].join(","));

        const csvContent = [csvHeader, ...csvRows].join("\n");

        // Create a blob from the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

        // Create a link element and trigger a download
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute("download", "negroni_data.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const plannedImps = {
        1: '240,000',
        2: '60,000',
        3: '500,000',
        4: '125,000',
    };

    const plannedDollars = {
        1: '0',
        2: '0',
        3: '$80,000',
        4: '$20,000',
    };

    // Helper to group data by group field
    const groupedData = data.reduce((acc, row) => {
        acc[row.group] = acc[row.group] || [];
        acc[row.group].push(row);
        return acc;
    }, {});

    return (
        <div style={{ color: colors.SKcustom[10], marginLeft: "10px" }}>
            <p>Campari • Negroni Week '24 Campaign</p>

            <Button variant="contained" color="primary" onClick={downloadCSV} style={{ border: `1px solid ${colors.SKcustom[10]}` }}>
                Download CSV
            </Button>

            {/* Flexbox to ensure the top scroll container is placed above the table */}
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: "1200px" }}>
                {/* Top scrollbar div */}
                <div ref={topScrollContainerRef} style={{ overflowX: "auto", marginBottom: "5px", }}
                    onScroll={() => handleScroll(topScrollContainerRef, scrollContainerRef)}>
                    {/* Dummy content to create a scrollbar with the same width as the table */}
                    <div style={{ width: tableWidth, height: '1px' }} />
                </div>
                {/* Table container with bottom scrollbar */}
                <TableContainer ref={scrollContainerRef} component={Paper} style={{ overflowX: "auto", }} onScroll={() => handleScroll(scrollContainerRef, topScrollContainerRef)}>

                    <Table>
                        <TableHead style={{ backgroundColor: '#2B4593' }}>
                            <TableRow>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Prisma Code</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Tactic</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Flight Start</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Flight End</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Buy Model</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Planned Imps</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Planned Dollars</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Total Imps</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Total Clicks</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Total Views</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Sept Imps</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Sept Clicks</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Sept Views</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Oct Imps</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Oct Clicks</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Oct Views</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(groupedData).map((group) => {
                                const groupRows = groupedData[group];
                                const groupTotalImps = groupTotals[group]?.totalImps || 0;
                                const groupTotalClicks = groupTotals[group]?.totalClicks || 0;

                                // Calculate totalViews and conditionally add chS02E01Views or chS02E01FamilyTreeViews
                                const groupTotalViews = groupTotals[group]?.totalViews || 0;

                                // if (group === "3") {
                                //     groupTotalViews += typeof chS02E01Views === "number" ? chS02E01Views : 0;
                                // } else if (group === "4") {
                                //     groupTotalViews += typeof chS02E01FamilyTreeViews === "number" ? chS02E01FamilyTreeViews : 0;
                                // }

                                const groupPlannedImps = plannedImps[group];
                                const groupPlannedDollars = plannedDollars[group];

                                return groupRows.map((row, rowIndex) => {
                                    let septViews = row.septViews; // Default septViews

                                    // Conditionally set septViews based on prismaCode
                                    if (row.prismaCode === "P2VSCY5") {
                                        septViews = chS02E01Views;
                                    } else if (row.prismaCode === "P2VSDH8") {
                                        septViews = chS02E01FamilyTreeViews;
                                    }

                                    return (
                                        <TableRow
                                            key={rowIndex}
                                            style={{
                                                backgroundColor: row.group % 2 === 0 ? HexToRgba(colors.SKcustom[31], 0.3) : undefined,
                                            }}
                                        >
                                            <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.prismaCode}</TableCell>
                                            <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>{row.tactic}</TableCell>
                                            <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.flightStart}</TableCell>
                                            <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.flightEnd}</TableCell>
                                            <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.buyModel}</TableCell>

                                            {/* Planned Imps - Only show once per group, span all rows in that group */}
                                            {rowIndex === 0 && (
                                                <TableCell
                                                    rowSpan={groupRows.length}
                                                    style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px', textAlign: 'center' }}
                                                >
                                                    {groupPlannedImps}
                                                </TableCell>
                                            )}

                                            {/* Planned Dollars */}
                                            {rowIndex === 0 && (
                                                <TableCell
                                                    rowSpan={groupRows.length}
                                                    style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px', textAlign: 'center' }}
                                                >
                                                    {groupPlannedDollars}
                                                </TableCell>
                                            )}

                                            {/* Total Imps, Clicks, Views */}
                                            {rowIndex === 0 && (
                                                <>
                                                    <TableCell
                                                        rowSpan={groupRows.length}
                                                        style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px', textAlign: 'center' }}
                                                    >
                                                        {groupTotalImps.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell
                                                        rowSpan={groupRows.length}
                                                        style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px', textAlign: 'center' }}
                                                    >
                                                        {groupTotalClicks.toLocaleString()}
                                                    </TableCell>
                                                    <TableCell
                                                        rowSpan={groupRows.length}
                                                        style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px', textAlign: 'center' }}
                                                    >
                                                        {groupTotalViews.toLocaleString()}
                                                    </TableCell>
                                                </>
                                            )}

                                            {/* Sept Imps, Clicks, Views */}
                                            <TableCell style={{ padding: '4px' }}>{row.septImps.toLocaleString()}</TableCell>
                                            <TableCell style={{ padding: '4px' }}>{row.septClicks.toLocaleString()}</TableCell>
                                            <TableCell style={{ padding: '4px' }}>{row.septViews}</TableCell>
                                            <TableCell style={{ padding: '4px' }}>{row.octImps.toLocaleString()}</TableCell>
                                            <TableCell style={{ padding: '4px' }}>{row.octClicks.toLocaleString()}</TableCell>
                                            <TableCell style={{ padding: '4px' }}>{row.octViews}</TableCell>
                                        </TableRow>
                                    );
                                });
                            })}
                        </TableBody>


                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default Negroni24;
